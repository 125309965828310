<script setup lang="ts">
import type { BookingOwnerRefusePayload } from '@yescapa-dev/ysc-api-js/legacy'
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import { BOOKING_OWNER_REFUSE_REASONS } from '@yescapa-dev/ysc-api-js/modern'
import type { EnumToPrimitiveUnion } from '@yescapa-dev/ysc-api-js/modern'
import type { AllNullable, SubmitAction } from '~/types/commons'

interface Props {
  submitAction: SubmitAction<BookingOwnerRefusePayload>
}

const props = defineProps<Props>()

const emit = defineEmits<{
  close: []
}>()

const form = ref<AllNullable<BookingOwnerRefusePayload>>({
  cancel_main_reason: null,
  cancel_other_reason_explanation: null,
})

const { t } = useI18n()
const { getReferentialOptions } = useReferentialStore()

const { sendingForm, setSendingForm } = useSendingForm()
provide(setSendingFormKey, { setSendingForm })

const reasonsCategories: {
  key: string
  title: string
  values: EnumToPrimitiveUnion<BOOKING_OWNER_REFUSE_REASONS>[]
}[] = [
  {
    key: 'availability',
    title: t('components.app_modal_booking_refuse.reasons.availability'),
    values: [
      BOOKING_OWNER_REFUSE_REASONS.REASON_UNAVAILABLE_CALENDAR,
      BOOKING_OWNER_REFUSE_REASONS.REASON_OLD_PRICE,
      BOOKING_OWNER_REFUSE_REASONS.REASON_UNAVAILABLE_FOR_INVENTORY,
      BOOKING_OWNER_REFUSE_REASONS.REASON_UNAVAILABLE_FOR_CHECK_IN,
    ],
  },
  {
    key: 'equipments',
    title: t('commons.equipments'),
    values: [
      BOOKING_OWNER_REFUSE_REASONS.REASON_SNOW,
      BOOKING_OWNER_REFUSE_REASONS.REASON_BABY,
      BOOKING_OWNER_REFUSE_REASONS.REASON_SEAT,
    ],
  },
  {
    key: 'settings',
    title: t('components.app_modal_booking_refuse.reasons.settings'),
    values: [
      BOOKING_OWNER_REFUSE_REASONS.REASON_DATE,
      BOOKING_OWNER_REFUSE_REASONS.REASON_KM,
      BOOKING_OWNER_REFUSE_REASONS.REASON_ANIMAL,
      BOOKING_OWNER_REFUSE_REASONS.REASON_SMOKING,
      BOOKING_OWNER_REFUSE_REASONS.REASON_TRIP,
      BOOKING_OWNER_REFUSE_REASONS.REASON_PURPOSE,
    ],
  },
  {
    key: 'other',
    title: t('commons.other'),
    values: [
      BOOKING_OWNER_REFUSE_REASONS.REASON_UNAVAILABLE_FOR_CHECK_IN,
      BOOKING_OWNER_REFUSE_REASONS.REASON_OTHER,
    ],
  },
]

const cancelReasonsOptions = computed(() =>
  reasonsCategories.reduce<Record<string, { value: EnumToPrimitiveUnion<BOOKING_OWNER_REFUSE_REASONS>, text: string }[]>>((acc, { key, values }) => {
    acc[key] = getReferentialOptions('cancel_reason_owner').filter(({ value }) => values.includes(value))
    return acc
  }, {}))

const onClose = () => {
  form.value.cancel_main_reason = null
  form.value.cancel_other_reason_explanation = null
  emit('close')
}

const onSubmit = async () => {
  const formValue = form.value
  assertAllNullableTypeIsType(formValue)

  await props.submitAction({ form: formValue })
}
</script>

<template>
  <YscModal @close="onClose">
    <template #title>
      {{ $t('components.app_modal_booking_refuse.title') }}
    </template>

    <p class="mb-4">
      {{ $t('components.app_modal_booking_refuse.description') }}
    </p>

    <YscForm
      id="refuse-booking-form"
      :submit-action="() => onSubmit()"
    >
      <div
        v-for="{ title, key } in reasonsCategories"
        :key="key"
        class="space-y-2"
      >
        <p class="text-xl font-semibold">
          {{ title }}
        </p>

        <YscInputRadioGroup
          :id="key"
          v-model="form.cancel_main_reason"
          :options="cancelReasonsOptions[key]"
          name="cancel_main_reason"
          required
        />
      </div>

      <YscInputTextarea
        v-if="form.cancel_main_reason === Constants.BOOKINGS.CANCEL_REASON.OWNER.REASON_OTHER"
        id="cancel_other_reason_explanation"
        v-model="form.cancel_other_reason_explanation"
        :placeholder="$t('data.booking.cancel_other_reason_explanation.placeholder')"
        :rows="3"
      />
    </YscForm>

    <template #footer>
      <div class="flex items-center justify-between">
        <button
          class="link link-primary"
          @click.stop.prevent="emit('close')"
        >
          {{ $t('commons.actions.back') }}
        </button>

        <YscButtonSubmit
          form="refuse-booking-form"
          :loading="sendingForm"
        />
      </div>
    </template>
  </YscModal>
</template>
